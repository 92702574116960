import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userId: "",
    name:"",
    setSetId:"",
    setLoginId:"",
    setTln:""
  },
  getters: {
  },
  mutations: {
    setUserId(state, userId) {
      state.userId = userId
    },
    setName(state,name){
      state.name = name
    },
    setSetId(state, setId) {
      state.setId = setId;
    },
    setLoginId(state, loginid) {
      state.loginid = loginid;
    },
    setSetTln(state, setTln) {
      state.setTln = setTln;
    },

  },
  actions: {
  },
  modules: {
  }
})
